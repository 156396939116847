<template>
<Transition name="cart-background">
    <div v-if="isOpen" class="cart-background" />
</Transition>
<Transition name="cart">
    <div v-if="isOpen" id="cart" class="shopping-cart-container jbg-scrollbar">
        <div class="cart-tray">
            <div class="cart-header">
                <h1>{{ $t('SHOPPING_CART.YOUR_CART') }}</h1>
                <!-- TODO: does this need to be a button for a11y reasons? -->
                <div class="close-container">
                    <div
                        id="cart-close" role="button"
                        tabindex="0"
                        class="close"
                        alt="close"
                        v-on:click="close"
                        v-on:keydown.enter="close"
                    >
                        <img src="../../assets/white-close.svg" alt="{{ $t('WIDGETS.CLOSE') }}">
                    </div>
                </div>
            </div>
            <div v-if="cartEmpty" class="empty-cart-container">
                <img
                    class="empty-cart-icon fa-kit fa-sad-cart"
                    alt=""
                >
                <div class="empty-cart-text paragraph-l">
                    {{ $t('SHOPPING_CART.EMPTY_CART') }}
                </div>
            </div>
            <div v-else>
                <div v-if="numSteamCodes > 0" class="steam-disclaimer">
                    <div v-if="numSteamCodes == 1" class="disclaimer-title">{{ $t('SHOPPING_CART.DISCLAIMER_TITLE_SINGULAR') }}</div>
                    <div v-else class="disclaimer-title">{{ $t('SHOPPING_CART.DISCLAIMER_TITLE_PLURAL') }}</div>
                    <ul>
                        <li>{{ $t('SHOPPING_CART.DISCLAIMER_TEXT[0]') }}</li>
                        <li>{{ $t('SHOPPING_CART.DISCLAIMER_TEXT[1]') }}</li>
                        <li>{{ $t('SHOPPING_CART.DISCLAIMER_TEXT[2]') }}</li>
                    </ul>
                </div>
                <div
                    v-for="(item, idx) in items"
                    :key="item.id"
                    class="line-item-container"
                >
                    <div class="product-img">
                        <img :src="item.image ?? ''" :alt="item.title" class="shopping-cart-thumb">
                    </div>
                    <div class="product-info">
                        <img
                            v-if="item.isSteamCode"
                            class="steam-code"
                            :alt="$t('SHOPPING_CART.STEAM_CODE_ALT')"
                            src="../../assets/steam-code.svg"
                        >
                        <div class="title-and-price">
                            <div class="product-title">
                                {{ item.title }}
                            </div>
                            <div class="price">
                                {{ item.price }}
                            </div>
                        </div>
                        <div v-if="item.isSteamCode && item.title !== 'YDKJ Classic'" class="platform">
                            {{ $t('SHOPPING_CART.PLATFORM') }}
                        </div>
                        <div v-if="item.isSteamCode && item.title === 'YDKJ Classic'" class="platform">
                            Windows Only
                        </div>
                        <div v-else class="platform">{{ variantText(item) }}</div>
                        <div class="quantity flex-row">
                            <v-select
                                v-model="lineItemLabels[idx].quantityLabel" :clearable="false" :searchable="false"
                                :options="quantityOptions"
                                class="quantity-select"
                                v-on:option:selected="updateQuantity($event, item)"
                            >
                                <template v-slot:open-indicator="{ attributes }">
                                    <span v-bind="attributes">
                                        <img alt="" src="../../assets/icons/down-arrow.png" width="12" height="12">
                                    </span>
                                </template>
                            </v-select>
                            <a
                                role="button" tabindex="0" class="cart-remove"
                                v-on:click="onTrashClick(item)"
                                v-on:keydown.enter="onTrashClick(item)"
                            >
                                {{ $t('SHOPPING_CART.REMOVE') }}
                            </a>
                            <div class="price-mobile">
                                {{ item.price }}
                            </div>
                        </div>
                    </div>
                </div>
                <hr>
                <div class="subtotal">
                    <div class="title">
                        {{ $t('CART.TOTAL') }} ({{ subtotal!.currencyCode }})
                    </div>
                    <div class="price">
                        {{ $shopify.formatDecimalPlaces(subtotal!.amount, $i18n.locale) }}
                    </div>
                </div>
                <a :href="checkoutUrl" target="_blank">
                    <ButtonElement
                        :id="`checkoutBtn`"
                        :class="`submit-btn`"
                    >
                        {{ $t('CART.CHECKOUT') }}
                    </ButtonElement>
                </a>
            </div>
        </div>
    </div>
</Transition>
</template>

<script lang="ts">
import { defineComponent, toRaw } from 'vue'
import type { JBGShopify } from '$services/shopify'
import { ShoppingCart } from '$services/shopify'
import ButtonElement from '$components/ButtonElement.vue'

export interface lineItemLabels {
    id: string
    quantityLabel: number
}

export default defineComponent({
    components: { ButtonElement },

    data() {
        const quantityOptions = []
        // we limit users from purchasing more than 5 codes of a single title
        // at a time for fraud protection reasons.
        for (let i = 1; i <= 5; i++) {
            quantityOptions.push(i)
        }

        return {
            quantityOptions
        }
    },

    computed: {
        items() {
            return toRaw(ShoppingCart.getCart())
        },

        cartQty() {
            return toRaw(ShoppingCart.getQty())
        },

        numSteamCodes() {
            let numCodes = 0
            this.items.forEach((item) => {
                if (item.isSteamCode) {
                    numCodes += item.quantity
                }
            })
            return numCodes
        },

        lineItemLabels() {
            const lineItemLabels: lineItemLabels[] = []
            this.items.forEach((item) => {
                // set label to current item.quantity
                lineItemLabels.push({
                    id: item.id,
                    quantityLabel: item.quantity
                })
            })
            return lineItemLabels
        },

        isOpen() {
            return ShoppingCart.isOpen()
        },

        cartEmpty(): boolean {
            return this.items.length === 0
        },

        subtotal(): JBGShopify.DisplayPrice {
            return toRaw(ShoppingCart.getSubtotal())
        },

        subtotalString(): string {
            const sub = this.subtotal
            return parseFloat(sub.amount).toLocaleString(this.$i18n.locale, { style: 'currency', currency: sub.currencyCode })
        },

        checkoutUrl(): string {
            return ShoppingCart.getCheckoutUrl()
        }
    },

    mounted() {
        void this.refreshCart()
    },

    methods: {
        close() {
            ShoppingCart.close()
        },

        async refreshCart() {
            await this.$shopify.refreshCart()
        },

        async onTrashClick(item: JBGShopify.DisplayItem) {
            try {
                await this.$shopify.removeItemFromCart(item.id)
            } catch (error) {
                console.error(error)
            }
        },

        async updateQuantity(newQuantity: number, item: JBGShopify.DisplayItem) {
            try {
                if (item.quantity === newQuantity) return
                await this.$shopify.updateItemQuantity(item, newQuantity)
            } catch (err) {
                // TODO: display some kind of error to user
                console.error(err)
            }
        },
        variantText(item: JBGShopify.DisplayItem) {
            if (item.optionString !== 'Default Title') {
                return item.optionString
            }
            return ''
        }
    }
})
</script>

<style lang="scss" scoped>
.cart-background {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 199;
    background-color: rgba(0, 0, 0, 0.70);
}

.shopping-cart-container {
    height: 100%;
    position: fixed;
    right: 0;
    top: 0;
    overflow-y: auto;
    /* ensures this will be on top of the zendesk chat button, which has a z-index of 999999 */
    z-index: 1000000;

    @media only screen and (min-width: 600px) {
        width: 576px;
        // Add a right-aligned gray stripe matching the cart bg color to provide a background for the scrollbar
        background: linear-gradient(90deg, transparent 90%, var(--surface-500) 30px);
    }

    @media only screen and (max-width: 599px) {
        width: 100%;
        scrollbar-width: none;
        -ms-overflow-style: none;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    // Custom scrollbar
    &.jbg-scrollbar {
        /* Firefox  */
        scrollbar-color: var(--primary-200) transparent;

        /* Chrome, Edge and Safari */
        *::-webkit-scrollbar {
            width: 10px;
        }

        *::-webkit-scrollbar-track {
            border-radius: 5px;
            background-color: transparent;
        }

        *::-webkit-scrollbar-thumb {
            border-radius: 5px;
            background-color: var(--primary-200);
        }
    }

    .cart-tray {
        min-height: 100%;
        border-radius: 32px 0 0 0;
        padding: 15px 17px 12px 24px;
        background: var(--surface-500);

        @media only screen and (max-width: 599px) {
            position: relative;
            top: 24px;
            border-radius: 24px 24px 0 0;
            max-width: 100vw;
        }
    }

    .cart-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 8px;

        h1 {
            @media only screen and (min-width: 600px) {
                font-size: 28px;
            }

            @media only screen and (max-width: 599px) {
                font-size: 20px;
            }
        }

        img {
            cursor: pointer;
        }

        .close-container {
            display: flex;
            justify-content: center;
            align-items: center;
            top: 20px;

            .close {
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 2;

                @media only screen and (max-width: 599px) {
                    background: var(--primary-50);
                    border-radius: 14px;
                    width: 28px;
                    height: 28px;
                    position: fixed;
                    right: 17px;
                    box-shadow:
                        0px 1px 4px -1px rgba(0, 0, 0, 0.10),
                        0px 3px 4px 0px rgba(0, 0, 0, 0.10),
                        0px 1px 8px 0px rgba(0, 0, 0, 0.08);

                    img {
                        width: 12px;
                        height: 12px;
                        // the following filter effects produce the color(--surface-900)
                        // https://codepen.io/sosuke/pen/Pjoqqp
                        filter:
                            brightness(0) invert(6%) sepia(12%) saturate(1799%) hue-rotate(186deg) brightness(96%) contrast(92%);
                    }
                }
            }
        }
    }

    .empty-cart-container {

        // TODO: We will want to resize the icon width based on media queries perchance
        .empty-cart-icon {
            font-size: 80px;
            padding-top: 90px;
            padding-bottom: 22px;
            margin: auto;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .empty-cart-text {
            margin: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-top: 8px;
        }
    }

    .steam-disclaimer {
        width: 524px;
        padding: 20px 60px 20px 24px;
        margin-left: -24px;
        border-radius: 3px 100px 100px 0px;
        background: var(--surface-50);
        margin-bottom: 26px;

        @media only screen and (max-width: 599px) {
            padding-right: 50px;
            padding-top: 16px;
            padding-bottom: 16px;
            max-width: calc(100vw - 12px)
        }

        .disclaimer-title {
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            margin-bottom: 8px;
        }

        ul {
            padding-left: 20px;
            margin: 0;

            li {
                font-style: normal;

                @media only screen and (min-width: 600px) {
                    font-size: 13px;
                    line-height: 21px;
                }

                @media only screen and (max-width: 599px) {
                    font-size: 11px;
                    line-height: 18px;
                }
            }
        }
    }

    .line-item-container {
        display: flex;
        padding: 11px 0;

        .product-img {
            padding-right: 2px;

            .shopping-cart-thumb {
                width: 140px;
                height: 140px;
                object-fit: cover;
                object-position: top;
                border-radius: 8px;
            }
        }

        .product-info {
            padding-left: 14px;
            margin: auto 0;
            width: 100%;

            @media only screen and (max-width: 599px) {
                min-width: 0;
            }

            p {
                margin-bottom: 12px;
            }

            .steam-code {
                margin-bottom: 4px;
            }

            .title-and-price {
                @media only screen and (min-width: 600px) {
                    display: flex;
                }

                .product-title {
                    width: 271px;
                    font-size: 18px;
                    font-weight: 500;

                    @media only screen and (max-width: 599px) {
                        width: 100%;
                        min-width: 0;
                    }
                }

                .price {
                    flex-grow: 1;
                    text-align: right;
                    margin: auto 0;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 600;

                    @media only screen and (max-width: 599px) {
                        display: none;
                    }
                }
            }

            .platform {
                font-size: 12px;
                margin-bottom: 16px;
            }

            .quantity {
                align-items: center;

                .quantity-select {
                    width: 48px;
                    height: 32px;
                    margin-right: 16px;

                    :deep(.vs__open-indicator) {
                        height: auto;
                        width: 12px;
                        margin: 4px 4px 0px -10px;
                    }

                    :deep(.vs__dropdown-toggle) {
                        max-height: 32px;
                        padding-bottom: 10px;
                    }

                    :deep(.vs__selected) {
                        flex-grow: 1;
                        position: static;
                        padding: 5px 0px 0px 10px;
                        color: var(--neutral-50);
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 12px;
                    }

                    :deep(.vs__selected-options) {
                        align-content: center;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        width: 48px;
                    }

                    :deep(.vs__dropdown-menu) {
                        color: var(--neutral-50);
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 12px;
                    }
                }

                .cart-remove {
                    font-size: 14px;
                    line-height: 32px;
                    font-weight: 500;
                    cursor: pointer;
                    flex: 1;
                    @media only screen and (max-width: 599px) {
                        font-size: 12px;
                    }
                }

                .price-mobile {
                    display: none;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 600;
                    text-align: right;
                    @media only screen and (max-width: 599px) {
                        display: block;
                    }
                }
            }
        }
    }

    .subtotal {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 24px;
        @media only screen and (max-width: 599px) {
            margin-top: 16px;
        }

        .title {
            font-size: 16px;
            line-height: 24px;
            align-content: center;
        }

        .price {
            font-size: 24px;
            font-weight: 700;
        }
    }

    #checkoutBtn {
        width: 100%;
        height: 48px;
        font-size: 16px;
        line-height: 16px;
        margin-top: 25px;
        margin-bottom: 200px;
        @media only screen and (max-width: 599px) {
            margin-top: 16px;
        }
    }

    hr {
        border: none;
        height: 1px;
        color: var(--neutral-300);
        background-color: var(--neutral-300);
        margin-top: 26px;
        @media only screen and (max-width: 599px) {
            margin-top: 18px;
        }
    }
}

// Cart slide in transition
.cart-enter-active {
    @media only screen and (min-width: 600px) {
        transition: all 0.3s ease-out;
    }

    @media only screen and (max-width: 599px) {
        transition: all 0.4s ease-out;
    }
}

.cart-leave-active {
    @media only screen and (min-width: 600px) {
        transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
    }

    @media only screen and (max-width: 599px) {
        transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
    }
}

.cart-enter-from,
.cart-leave-to {
    @media only screen and (min-width: 600px) {
        transform: translateX(576px);
    }

    @media only screen and (max-width: 599px) {
        transform: translateY(100vh);
    }
}

// Cart background fade in
.cart-background-enter-active {
    transition: opacity 0.3s ease;
}

.cart-background-leave-active {
    transition: opacity 0.2s ease;
}

.cart-background-enter-from,
.cart-background-leave-to {
    opacity: 0;
}
</style>
