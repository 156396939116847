<template>
<div
    role="button"
    class="menu-container" :class="{ 'open-text': isOpen && !iconOnly }"
    tabindex="0"
    :aria="text"
    v-on:keyup.enter="toggle"
>
    <div class="menu-label">
        <div
            v-if="iconOnly" ref="tiBtn"
            role="button" tabindex="0" class="icon-padding ti-btn"
            :class="{ 'open-icon': isOpen }"
            v-on:click="toggle" v-on:keydown.enter="toggle"
        >
            <span class="icon" :class="faIcon" />
        </div>
        <div
            v-else-if="menuType === 'user'" role="button"
            :class="{ 'open-icon': isOpen }" tabIndex="0"
            :aria="$t('USER_MENU.ACCOUNT')" v-on:click="toggle" v-on:keydown.enter="toggle"
        >
            <img id="profile-icon" class="profile-icon" :src="defaultProfileImgSrc" alt="">
        </div>
        <div
            v-else role="button"
            tabindex="0" class="submenu selected paragraph semibold" :class="{ 'open-icon': isOpen, 'duo-icon': duoToneIcon }"
            v-on:click="toggle" v-on:keydown.enter="toggle"
        >
            {{ text }}
            <span v-if="icon" class="option-icon" :class="faIcon" />
            <div class="caret" :class="isOpen ? 'open' : ''"><i class="fa-solid fa-caret-right" /></div>
        </div>
    </div>
    <!-- open menu -->
    <div
        v-if="isOpen" ref="menu" class="dropdown-menu"
        :class="{open: isOpen, 'align-right': alignRight, 'align-left': alignLeft, 'align-center': alignCenter}"
    >
        <div
            v-for="(option, i) of options" :id="`nav-menu-item-${i}-${option.value}`"
            :key="i" role="button"
            class="nav-menu-item item paragraph-l" :class="{ 'top-item': i === 0, 'user-acct': menuType === 'user' }"
            tabindex="0" v-on:click="handleChange(option)" v-on:keydown.enter="handleChange(option)"
        >
            {{ option.text }}
            <span v-if="!!option.icon" class="option-icon" :class="faOptionIcon(option)" />
        </div>
    </div>
</div>
</template>

<script lang="ts">
import type { PropType } from 'vue'
import type { NavMenuOption, menuType } from './NavigationBar.vue'

export default defineNuxtComponent({
    components: {
    },

    props: {
        menuType: {
            type: String as PropType<menuType>,
            required: true
        },
        text: String,
        icon: String,
        duoToneIcon: Boolean,
        iconOnly: Boolean,
        options: {
            type: Array as PropType<NavMenuOption[]>,
            required: false
        },
        // Alignment of menu
        alignLeft: Boolean,
        alignRight: Boolean,
        alignCenter: Boolean,

        userAuthenticated: {
            type: Boolean,
            required: false
        },
        showSigninButton: {
            type: Boolean,
            required: false
        }
    },

    emits: ['menuSelect', 'open', 'close', 'logout', 'login'],

    data() {
        return {
            isOpen: false
        }
    },

    computed: {
        faIcon(): string {
            let iconClasses = ''
            if (this.duoToneIcon) {
                // fa-duotone
                iconClasses += 'fa-duotone'
            } else {
                // fa-solid
                iconClasses += 'fa-solid'
            }

            if (this.icon) {
                iconClasses = `${iconClasses} ${this.icon}`
            }

            return iconClasses
        },

        // we could load this as an inline svg instead and style the fill
        defaultProfileImgSrc() {
            if (this.isOpen) {
                return '/images/play/mayo_hover.svg'
            }
            return '/images/play/mayo.svg'
        }
    },

    watch: {
        $route() {
            // close any open menus on route
            this.isOpen = false
        }
    },

    beforeUnmount() {
        document.removeEventListener('click', this.onDocumentClick)
    },

    methods: {
        close() {
            this.$emit('close')
            this.isOpen = false

            document.removeEventListener('click', this.onDocumentClick)
        },

        faOptionIcon(option: NavMenuOption): string {
            if (option.icon) {
                return `fa-solid ${option.icon}`
            }

            return ''
        },

        handleChange(option: NavMenuOption) {
            this.$emit('menuSelect', option.value)
            this.close()
        },

        handleBlur($event: FocusEvent) {
            const targetElement = $event.relatedTarget as Element
            if (!targetElement || !(targetElement.id.startsWith('nav-menu-item'))) {
                this.close()
            }
        },

        logoutUser() {
            this.$emit('logout')
        },

        // Close when clicking anywhere outside the open menu
        onDocumentClick() {
            if (!this.isOpen) return
            this.close()
        },

        open() {
            this.$emit('open')
            this.isOpen = true

            setTimeout(() => {
                document.addEventListener('click', this.onDocumentClick)
            }, 50)
        },

        toggle() {
            this.isOpen ? this.close() : this.open()
        }
    }
})
</script>

<style lang="scss" scoped>
@use "$styles/kit.scss" as *;

.menu-container {
    color: var(--neutral-50);
    cursor: pointer;
    position: relative;
    z-index: 1;

    .align-right {
        right: 0;
        border-radius: 4px;
    }

    .align-left {
        left: 0;
        border-radius: 4px;
    }

    .align-center {
        left: 50%;
        transform: translateX(-50%);
        border-radius: 4px;
    }

    .menu-label {
        display: inline-block;
        margin: 4px 0;
        line-height: 0;
        margin: 4px 0;
        vertical-align: middle;
        .submenu {
            display: flex;
            gap: 5px;
        }
        .caret {
            align-self: center;
            font-size: 12px;
            transition: all .2s;
            &.open {
                transform: rotate(90deg);
            }
        }
    }

    .dropdown-menu {
        border-radius: 4px;
        background-color: var(--surface-300);
        position: absolute;
        padding: 8px;
        min-width: 240px;
        top: 100%;
        width: 100%;
        z-index: 2;
    }

    &.icon-nav-link {
        .menu-label:hover {
            border-radius: unset;
            background-color: unset;
            color: var(--primary-200);
        }
    }

    .icon {
        height: 20px;
        width: 20px;

        :hover {
            background-color: transparent;
            color: var(--primary-200)
        }
    }

    .item:hover {
        background-color: var(--surface-50);
        border-radius: inherit;
    }


    .nav-menu-item {
        color: var(--neutral-50);
        display: flex;
        font-weight: normal;
        padding: 12px;

        .option-icon {
            padding-left: 10px;
            margin-top: auto;
            margin-bottom: auto;
        }

        @include mq-large {
            .option-icon {
                width: 28px;
            }
        }

        @include mq-medium {
            .option-icon {
                width: 14px;
            }
        }

        @include mq-small {
            .option-icon {
                width: 14px;
            }
        }

        @include mq-xsmall {
            padding: 8px 20px;
            margin: 8px 0;
            fill: var(--neutral-200);

            .option-icon {
                width: 14px;
            }
        }

        &.user-acct {
            flex-direction: row-reverse;
            justify-content: flex-end;

            .option-icon {
                margin-right: 8px;
            }
        }
    }

    .profile-icon {
        height: 32px;
        width: 32px;
    }

    .ti-btn {
        outline: 0;
        overflow: hidden;
        position: relative;
        user-select: none;
        transition: box-shadow 150ms ease-out;

        &.open-icon {
            color: var(--primary-200);
        }
    }
}
</style>
